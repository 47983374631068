body {
	background-color: #b6b1b1;
}

/* Navigation bar styles */
.navbar-custom {
	padding-left: 10% !important;
	padding-right: 10% !important;
	background-color: #18181b !important;
	box-shadow: 0 1px 5px #18181b;
	@media (max-width: 500px) {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
}
.navbar-link {
	color: #fdd803;
	text-decoration: none;
}
.navbar-link:hover {
	color: white !important;
}
.navbar-nav .nav-link.active {
	color: white;
}
.burger-button {
	background-color: #fdd803;
}
.side-menu {
	background-color: #18181b !important;
}
.contact-button {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #18181b;
	font-weight: 500;
	padding: 10px;
	background-color: #fdd803;
	border: #fdd803;
	border-radius: 10px;
	outline: none;
}
.contact-button:hover {
	color: white;
	font-weight: 500;
	background-color: #a8900a !important;
	border: white;
	transition: 0.5s;
}

@media screen and (max-width: 960px) {
	.carousel-slide {
		width: 100%;
	}
}
@media screen and (max-width: 500px) {
	.burger-button {
		height: 30px;
		width: 40px;
	}
	.navbar-toggler-icon {
		position: relative;
		width: 20px;
		height: 40px;
		margin: -9px 0 0 -2px;
	}
}
